//
//
//
//
//
//
//
//
//
//
//
//

import { getCategory } from '@/api/index'
import spHeader from '@/components/common/sp-header'
import spFooter from '@/components/common/sp-footer'
import spCopy from '@/components/common/sp-copyright'
import spFloorAside from '@/components/spIndexFloorAside'

export default {
  name: 'LayoutDefault',
  components: {
    spFloorAside,
    spHeader,
    spFooter,
    spCopy
  },
  data () {
    return {
      category: []
    }
  },
  mounted () {
    this.getCategory()
  },
  methods: {
    // 获取商品分类
    getCategory() {
      getCategory(this.$axios, {}).then(res => {
        this.category = res.data
      })
    }
  }
}
