//
//
//
//
//
//
//
//
//
//

import spFooter from '@/components/common/sp-footer'
import spCopy from '@/components/common/sp-copyright'

export default {
  name: 'LayoutForUser',
  components: {
    spFooter,
    spCopy
  },
  data () {
    return {}
  },
  mounted () {},
  methods: {}
}
