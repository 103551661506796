//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { getCookieByKey } from '@/utils/cookie'
import { setCookie } from '@/utils/cookie'
import { getSearchKeys, topmenu } from '@/api/index'

export default {
  name: 'SpHeader',
  props: {
    menu: {
      type: Boolean,
      default: true
    },
    categoryShow: {
      type: Boolean,
      default: true
    },
    category: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      local: ['zh', 'cht', 'en', 'fra', 'ara'],
      localLabel: ['简体中文', '繁體中文', 'English', 'Français', 'بالعربية'],
      userInfo: {},
      linkh5: "https://h5.sixking.com",
      classification: false,
      keys: [],
      eses: "",
      keywords: "",
      activeMenu: []
    }
  },
  mounted() {
    this.getMenuTop()
    const userInfo = getCookieByKey('fat_infos', true)
    if (userInfo) {
      this.userInfo = JSON.parse(userInfo)
    }
    const lang = getCookieByKey('fat_lang', true)
    this.$i18n.locale = lang
    this.getSearchKeys()
    // this.setLocal(lang)
  },
  methods: {
    // 
    async getMenuTop () {
      const params = {
        type: 'pc菜单'
      }
      const { code, data } = await topmenu(this.$axios, params)
      if (code === 1) {
        this.activeMenu = data
      }
    },
    setLocal(e) {
      this.$i18n.locale = e
      setCookie('lang', e, {})
      this.$emit('local', e)
      window.location.reload()
    },
    ese(index) {
      console.log(this.category[index].children)
      this.eses = index
    },
    showClassification() {
      this.classification = true
      this.categoryShow = true;
      this.eses=""
    },
    hideClassification() {
      this.classification = false
      this.eses=""
    },
    getSearchKeys() {
      getSearchKeys(this.$axios, {}).then(({ code, data, msg }) => {
        if (code === 1) {
          this.keys = data
        } else {
          this.$message.error(msg)
        }
      })
    },
    getSearchByTags(item) {
      console.log(item)
      const query = {}
      query[item.remark] = item.wb_value
      this.$router.push({
        path: '/goods/search',
        query
      })
    },
    getSearchByKeys() {
      if (this.keywords !== '') {
        this.$router.push({
          path: '/goods/search',
          query: {
            keys: this.keywords
          }
        })
      }
    }
  }
}
