//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'SpFooter',
  computed: {
    link() {
      return [
        {
          title: this.$t('footer.title0'),
          menu: [
            { src: '18', text: this.$t('footer.text01') },
            { src: '19', text: this.$t('footer.text02') },
            { src: '20', text: this.$t('footer.text03') },
            { src: '21', text: this.$t('footer.text04') }
          ]
        },
        {
          title: this.$t('footer.title1'),
          menu: [
            { src: '25', text: this.$t('footer.text11') },
            { src: '26', text: this.$t('footer.text12') },
            { src: '27', text: this.$t('footer.text13') },
            { src: '28', text: this.$t('footer.text14') }
          ]
        },
        {
          title: this.$t('footer.title2'),
          menu: [
            { src: '29', text: this.$t('footer.text21') },
            { src: '30', text: this.$t('footer.text22') },
            { src: '31', text: this.$t('footer.text23') }
          ]
        },
        {
          title: this.$t('footer.title3'),
          menu: [
            { src: '32', text: this.$t('footer.text31') },
            { src: '33', text: this.$t('footer.text32') },
            { src: '34', text: this.$t('footer.text33') }
          ]
        },
        {
          title: this.$t('footer.title4'),
          menu: [
            { src: '39', text: this.$t('footer.text53') },
            { src: '40', text: this.$t('footer.text54') },
            { src: '41', text: this.$t('footer.text55') },
            { src: '42', text: this.$t('footer.text56') },
            { src: '43', text: this.$t('footer.text57') }
          ]
        },
        {
          title: this.$t('footer.title5'),
          menu: [
            { src: '37', text: this.$t('footer.text51') },
            { src: '38', text: this.$t('footer.text52') },
            { src: '36', text: this.$t('footer.text41') },
            { src: '35', text: this.$t('footer.text42') }
          ]
        }
      ]
    }
  },
  methods: {
    toArticleIndex(id) {
      this.$router.push({
        path: '/article/',
        query: {
          id
        }
      })
    }
  }
}
