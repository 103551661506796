//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'SpIndexFloorAside',
  props: {
    category: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      scr_state: false
    }
  },
  mounted() {
    // 在浏览器环境下
    if (process.client) {
      // const links = $('.sp-index__floor-link')
      // const floor = $('.sp-index__floor')
    }
    window.addEventListener('scroll', this.handleScroll, true)
  },
  methods: {
    // 返回顶部
    scrollToTop() {
      if (process.client) {
        $("html,body").animate({
          scrollTop: 0
        }, 1000)
      }
    },
    scrollToFloor(index) {
      if (process.client) {
        const offsetHeight = $('.sp-index__floor').eq(index).offset().top
        $("html,body").animate({
          scrollTop: offsetHeight
        }, 1000)
      }
    },
    handleScroll (e) {
      let scrollY =  e.target.scrollingElement.scrollTop;
      //tab定位
      if (scrollY > 474) {
        this.scr_state = true
      } else {
        this.scr_state = false
      }
    },
  }
}
