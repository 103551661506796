//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { getCookieByKey, setCookie } from '@/utils/cookie'
import spFooter from '@/components/common/sp-footer'
import spCopy from '@/components/common/sp-copyright'

export default {
  name: 'LayoutForMember',
  components: {
    spFooter,
    spCopy
  },
  data () {
    return {
      local: ['zh', 'cht', 'en', 'fra', 'ara'],
      localLabel: ['简体中文', '繁体中文', 'english', '法语', '阿拉伯语'],
      userInfo: {},
      key: 0
    }
  },
  mounted () {
    const userInfo = getCookieByKey('fat_infos', true)
    if (userInfo) {
      this.userInfo = JSON.parse(userInfo)
    }
    const lang = getCookieByKey('fat_lang', true)
    // this.$i18n.local = lang
    this.setLocal(lang)
  },
  methods: {
    setLocal(e) {
      this.$i18n.locale = e
      setCookie('lang', e, {})
      // this.$router.go(0)
      this.key++
    }
  }
}
