//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { getCategory } from '@/api/index'
import backTop from '@/components/BackTop/index'
import spHeader from '@/components/common/sp-header'
import spFooter from '@/components/common/sp-footer'
import spCopy from '@/components/common/sp-copyright'

export default {
  name: 'LayoutDefault',
  components: {
    backTop,
    spHeader,
    spFooter,
    spCopy
  },
  data () {
    return {
      key: 0,
      category: []
    }
  },
  mounted () {
    this.getCategory()
  },
  methods: {
    // 获取商品分类
    getCategory() {
      getCategory(this.$axios, {}).then(res => {
        this.category = res.data
      })
    },
    setKeys(e) {
      console.log('setKeys', e)
      this.key++
      this.local = e
      this.$i18n.local = e
      this.getCategory()
    }
  }
}
