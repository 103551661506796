// 商品分类
export function getCategory (request) {
  return request({
    url: 'index/category',
    method: 'get',
    data: {
      withbrandgoods: 1
    }
  })
}

// 首页轮播
export function getSwiper(request, data) {
  return request({
    url: 'index/lunbo',
    method: 'get',
    data
  })
}

// 每日必看
export function getHotGoods (request, data) {
  return request({
    url: 'index/everyday',
    method: 'get',
    data
  })
}

// 热门搜索
export function getSearchKeys (request, data) {
  return request({
    url: 'index/searchWords',
    method: 'get',
    data
  })
}

// 友情链接
export function getFriendLink (request) {
  return request({
    url: 'index/friendlink',
    method: 'get'
  })
}
// PC头部菜单
export function topmenu (request, data) {
  return request({
    url: 'index/topmenu',
    method: 'get',
    data
  })
}