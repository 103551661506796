//
//
//
//
//
//

export default {
  data() {
    return {}
  },
  head() {
    return {
      link : [
        {
          rel : 'icon',
          type : 'image/x-icon',
          href : ''

        }
      ]
    }
  }
}
